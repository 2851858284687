import React from 'react';
import { BrowserRouter as Router, Routes, Route, Switch } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css';
import Logout from './pages/Logout/Logout';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import ResetPassword from './pages/Reset Password/ResetPassword';

export default function App() {
  
    return (
        <div className="theme-dark">
          <Router>
          <ReactNotifications />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route exact path="/register" element={<Register />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/logout" element={<Logout />} />
            </Routes>
          </Router>
        </div>
    )
};