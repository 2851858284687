export const loginRequest = async (email, password) => {

    const response = await fetch(process.env.REACT_APP_BACK_END + '/login', {
        method: 'POST',
        //headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
        //credentials: 'include',
        //mode: 'no-cors',
        body: JSON.stringify({
          email: email,
          password: password,
          path: window.location.href
        })
      })
      .then(response => response.json());
    // if (response.success) {
    //     localStorage.setItem('user', JSON.stringify(response.success.user));
    // }
    return response;
};

export const registerUser = async (email, password) => {
  
    const response = await fetch(process.env.REACT_APP_BACK_END + '/register', {
          method: 'POST',
          body: JSON.stringify({
            email: email,
            password: password
          })
        }).then(response => response.json());
    
    return response;
};

export const confirmRegistration = async (code) => {

  const response = await fetch(process.env.REACT_APP_BACK_END + '/register/confirm', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({
          code: code
      })
  }).then(response => response.json());

  return response;
};

export const resetPasswordRequest = async (email) => {

    const response = await fetch(process.env.REACT_APP_BACK_END + '/auth/reset-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify({
          email: email
        })
      }).then(response => response.json());
    
    return response;
};

export const resetPassword = async (code, password) => {
      
    const response = await fetch(process.env.REACT_APP_BACK_END + '/auth/reset-password/confirm', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify({
          code: code,
          password: password
        })
      }).then(response => response.json());

    return response;
};

export const checkAuth = async () => {

  const response = await fetch(process.env.REACT_APP_BACK_END + '/auth', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      credentials: 'include'
    }).then(response => {
      if (response.status === 401) return window.location = '/logout';
      return response.json()
    });

  if (response.success) {
      if (process.env.NODE_ENV === 'development' && !response.success.user.hasOwnProperty('isAdmin')) {

        return window.location = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ';
      }
      localStorage.setItem('user', JSON.stringify(response.success.user));
  }
  return response;
};